import React from "react"
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Terminal, Mail, Linkedin, Compass, Home } from 'react-feather';
import { checkPropTypes } from "prop-types"
import ProjectCards from "../components/cards"


const Images = props => (
  
  <div>
    {props.items.map((data, index) => {
      return <Img fluid={data.img.childImageSharp.fluid} alt={data.title} style={{ float: "left", marginRight: "1rem", width: 150 }}></Img>
    })}
  </div>
  )

  const FirstImage = props => (
  
    <div>
        <Img fluid={props.items[0].img.childImageSharp.fluid} alt={props.items[0].title} style={{ float: "left", marginRight: "2rem", width: 150 }}></Img>
    </div>
    )

export const query = graphql`
  query {
    allProjectsYaml {
      nodes {
        title
        summary
        slug
        images {
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
        } 
      }
    }
    allSkillsYaml {
      nodes {
        title
        description
        slug
        path
      }
    }
  }
`


const MovingInnovationsPage = props => (
  <Layout location={props.location} crumbLabel="Moving Innovations">
    <SEO title="Moving Innovations" slug="/"/>
    <div className="main-narrow">
        <h2>Moving Innovations</h2>
        <p>
        Our mission is to give technology startups a competitive advantage with superior algorithms.
        </p>
        <p>
    In designing new algorithms, one needs to understand the limitations of software and hardware, even with ever growing computational resources. Effective development of new solutions should build as much as possible on existing algorithms and libraries. By keeping the real innovative part as small as possible, you reduce development time and gain maintainability. 
    </p>
    <p>
    We believe that for each new project, it is important to decide on the currently best possible technology available for the problem at hand, and not only to rely on previous experience. In the rapidly changing field of IT it is import to understand the general direction in which the technology is heading, without falling too fast for the latest hypes.
    </p>
    <p>
    We have experience with all aspects of the development of new applications, from idea to architecture to development to cloud hosting. Our strength is in the backend side of applications.
    </p>

    Please check our portfolio of recent projects to get an idea of possibilities:
    </div>
    <h2>Recent Projects</h2>
    <ProjectCards projects={props.data.allProjectsYaml.nodes}></ProjectCards>

    <div style = {{display: `block`, clear: `both`}}></div>
    <div className="company-address">
      
      <p><b>Moving Innovations B.V.</b></p>
      <p>Brouwersstraat 128</p>
      <p>2013 WR Haarlem</p>
      <p>The Netherlands</p>
      <p>KvK: 71112383</p>
      <p>BTW: NL858584505B01</p>
    </div>

  </Layout>
)


export default MovingInnovationsPage
